import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro00 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Rec_01.webp'
import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Android_Rec_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Android_Rec_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Android_Rec_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Android_Rec_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Android_Rec_05.webp'


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro00} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Camera Recordings"
          subtitle="Press the Recording button to enter the Recording menu."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Select the File Location"
          subtitle="Select the video source - your cameras SD card storage, local storage on your phone or from your INSTAR Cloud Account."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Select your Camera"
          subtitle="Select the camera you want to review recordings from."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Filter your Recordings"
          subtitle="Select to list all recordings or filter for alarm, planned or manual recordings."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Videos & Snapshots"
          subtitle="SYou can filter to only display videos or snapshots."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Video Playback"
          subtitle="Select a date and click on a video recording to download and play it."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function RecGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default RecGallery